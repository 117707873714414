import React, { useState, useEffect } from "react";
import Fuse from "fuse.js";

// This modal is only for when the JSON response has an "alternative" or "similar" field.
// Code selection will replace the "unspecified" code with the chosen code and update DB (optional).
export default function AlternativeCodeModal({
  code,
  setShowAlternativeCodes,
  isUnspecifiedAnemia,
  selectedCodes,
  setSelectedCodes,
  setCodesChange,
  patientID,
}) {
  const [selectedOption, setSelectedOption] = useState(null);

  // Decide which array to display: "alternatives" or "similar"
  const alternativeCodes = code.alternatives
    ? code.alternatives
    : isUnspecifiedAnemia(code.name) && code.similar
    ? code.similar
    : [];

  // --- SEARCH STATE ---
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [filteredCodes, setFilteredCodes] = useState([]);
  const [fuseObject, setFuseObject] = useState(null);
  const [warningMessage, setWarningMessage] = useState("");

  // Load ICD-10 data for searching
  useEffect(() => {
    async function loadData() {
      try {
        const response = await fetch("/json/ICD10Data.json");
        const data = await response.json();
        const fuse = new Fuse(data, {
          keys: ["code", "long"], // search by code or description
          threshold: 0.3,
        });
        setFuseObject(fuse);
      } catch (error) {
        console.error("Failed to load ICD-10 data:", error);
      }
    }
    loadData();
  }, []);

  // Debounce the raw searchQuery so we only set debouncedQuery after 300ms of inactivity
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(searchQuery.trim());
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  // Whenever debouncedQuery changes, run the Fuse search
  useEffect(() => {
    if (!fuseObject) return;

    if (!debouncedQuery) {
      setFilteredCodes([]);
      setWarningMessage("");
      return;
    }

    // Run Fuse
    const results = fuseObject.search(debouncedQuery, { limit: 50 });
    let items = results.map((r) => r.item);

    // Blocked terms (exact)
    const blockedTerms = [
      "D509",
      "D649",
      "ANEMIA, UNSPECIFIED",
      "IRON DEFICIENCY ANEMIA, UNSPECIFIED",
    ];
    const queryUpper = debouncedQuery.toUpperCase();

    // 1. Filter out any codes that match blocked terms
    items = items.filter((icd) => {
      // Compare code in uppercase to blocked terms
      const icdCodeUpper = icd.code?.replace(".", "").toUpperCase();
      // ^ if your dataset uses "D50.9", but your blocked term is "D509",
      //   remove the dot for a direct match.

      return !blockedTerms.includes(icdCodeUpper);
    });

    // 2. Show a warning if the user typed an exact blocked term
    if (blockedTerms.includes(queryUpper)) {
      setWarningMessage(
        "Do not recommend adding unspecified anemia, please add a more specific code"
      );
    } else {
      setWarningMessage("");
    }

    setFilteredCodes(items);
  }, [debouncedQuery, fuseObject]);

  const handleClose = () => {
    setShowAlternativeCodes(false);
  };

  // Called when user selects either an alternative code or a search result
  const onChange = (option) => {
    setSelectedOption(option);
  };

  const handleReplace = async () => {
    if (!selectedOption) return;

    // 1) Create updated array of codes by replacing the original code
    //    We'll preserve the original codeType, but replace everything else.
    const updatedCodes = selectedCodes.codes.map((c) => {
      if (c.code === code.code) {
        // The new code inherits the old code's codeType
        return {
          code: selectedOption.code,
          name: selectedOption.long || selectedOption.name,
          codeType: c.codeType,
        };
      }
      return c;
    });

    // 2) Update the parent's selectedCodes
    setSelectedCodes({ codes: updatedCodes });

    // 3) Trigger any parent re-renders if needed
    setCodesChange(true);

    // 4) Close the modal
    handleClose();
  };

  return (
    <div className="fixed bg-gray-800 bg-opacity-80 inset-0 flex items-center justify-center z-50">
      <div className="relative flex flex-col items-center justify-center bg-white p-10 rounded-lg shadow-lg w-[90vw] max-w-[600px]">
        <button
          onClick={handleClose}
          className="text-2xl absolute top-3 right-5 font-extrabold hover:text-accent1 text-light-grey"
        >
          X
        </button>
        <svg
          className="w-[80px] h-[80px] text-accent1 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M8 20V7m0 13-4-4m4 4 4-4m4-12v13m0-13 4 4m-4-4-4 4"
          />
        </svg>

        <h2 className="flex flex-col text-3xl text-accent1 font-black mt-2 mb-1">
          <span className="text-center">Replace Code</span>
        </h2>
        <p className="flex flex-col text-light-grey text-left">
          <span>
            Please select one of the alternative codes below or search for a
            code to replace the unspecified code:
          </span>
        </p>

        {/* Unspecified code details */}
        <h3 className="flex flex-col text-ktx-red font-black mb-1 mt-2 text-left">
          <span>{`${code.code} ${code.name}`}</span>
        </h3>

        {/* --- ALTERNATIVE CODES LIST --- */}
        <div className="mt-2 mb-4 w-full">
          {alternativeCodes && alternativeCodes.length > 0 ? (
            <div className="border rounded p-2 max-h-40 overflow-auto">
              {alternativeCodes.map((option, index) => (
                <label
                  className="flex flex-row text-accent1 items-center gap-2 mb-1 cursor-pointer"
                  key={index}
                >
                  <input
                    type="radio"
                    name="codes"
                    className="cursor-pointer"
                    checked={selectedOption?.code === option.code}
                    onChange={() => onChange(option)}
                  />
                  {option.code}, {option.name}
                </label>
              ))}
            </div>
          ) : (
            <div className="text-gray-500 text-sm font-semibold text-center">
              There are no alternative codes associated with this code.
            </div>
          )}
        </div>

        {/* --- SEARCH SECTION --- */}
        <div className="w-full mb-4">
          <label className="block text-accent1 mb-2 font-bold">
            Or search for a different code:
          </label>
          <div className="relative w-full mb-2">
            <input
              type="text"
              placeholder="Search ICD-10..."
              className="border rounded w-full py-2 px-3 text-accent1"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          {/* Warning */}
          {warningMessage && (
            <div className="text-red-500 text-sm font-semibold mb-2">
              {warningMessage}
            </div>
          )}

          <div className="border rounded max-h-40 overflow-auto">
            {filteredCodes.map((icd, idx) => (
              <label
                key={`${icd.code}-${idx}`}
                className="flex flex-row text-accent1 items-center gap-2 p-2 border-b last:border-b-0 cursor-pointer hover:bg-gray-100"
              >
                <input
                  type="radio"
                  name="searchCodes"
                  checked={selectedOption?.code === icd.code}
                  onChange={() => onChange(icd)}
                />
                {icd.code}, {icd.long}
              </label>
            ))}

            {filteredCodes.length === 0 && debouncedQuery.length > 0 && (
              <div className="p-2 text-gray-500">No results found.</div>
            )}
          </div>
        </div>

        <button
          onClick={handleReplace}
          className="bg-accent2 hover:bg-purple2 text-white py-2 px-4 rounded"
          style={{ width: "30vw" }}
        >
          Replace
        </button>
      </div>
    </div>
  );
}

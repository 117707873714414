import axios from "axios";
import jwt_decode from "jwt-decode";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import clipboard from "../../assets/clipboard.svg";
import css from "./noteList.module.css";
// import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import AddCodeModal from "./ICD10Workflow/AddCodeModal";
import CodeSuggestionsModal from "./ICD10Workflow/CodeSuggestionsModal";
import AlternativeCodeModal from "./ICD10Workflow/AlternativeCodeModal";
import DeleteCodeModal from "./ICD10Workflow/DeleteCodeModal";

// TODO: implement ketonis rte with custom styling

// NOTE: react quill rte is commented out for V2-launch d/t issues with AI model and epic copy/paste functionality.

//Allows you to edit the patient and note associated with the patient.
const EditPatient = ({
  patient,
  patientsChange,
  setPatientsChange,
  org,
  isEditing,
  setIsEditing,
  name,
  generateRecordLink,
  status,
  ehrUploadStatus,
}) => {
  //when we edit the text we update the database
  const [sendTo4D, setSendTo4D] = useState(false);
  const [sendToOncoEMR, setSendToOncoEMR] = useState(false);
  const [sendToAthena, setSendToAthena] = useState(false);
  const [sendToEHR, setSendToEHR] = useState(false);
  const [sendToModMed, setSendToModMed] = useState(false);

  const [codesChange, setCodesChange] = useState(false);
  const [showUnspecifiedAlert, setShowUnspecifiedAlert] = useState(false);

  // state variable to show code suggestions modal
  const [showSuggestions, setShowSuggestions] = useState(false);

  //state variable to show add code modal
  const [showAddCode, setShowAddCode] = useState(false);

  const [currentSelectedCode, setCurrentSelectedCode] = useState(null);

  const [isPrimaryCode, setIsPrimaryCode] = useState(false);
  const [isSecondaryCode, setIsSecondaryCode] = useState(false);

  const isCodingTestProvider = [
    //"Dr. Caracitas",
    //"Dr. Farooq",
    "Dr. Licitra",
    "Dr. Smith",
    "Dr. Ronnen",
    "Michael Doczekalski",
    "Julisa Debase",
    "Diana Gonzalez Vazquez",
    "China Payne",
    "Michelle Regragui",
    "Emil Martoral Agosto",
  ].includes(name);

  const navigate = useNavigate();

  const redirectToSettings = () => {
    navigate("/settings");
  };

  const editText = async (id) => {
    try {
      const body = {
        description,
        note,
        LOSCode,
        codingInfo,
        encounterTime,
        timeBasedCode,
        icd_10_codes,
        orders,
        hcc_codes,
        cpt_codes,
      };

      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", localStorage.token);

      await fetch(`${process.env.REACT_APP_API_URL}/notes/todos/${id}`, {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(body),
      });

      // 2) Save *selectedCodes* to DB only now
      await saveSelectedCodes(selectedCodes);

      setPatientsChange(true);

      if (org === "4D EMR") {
        const confirmed = window.confirm(
          "Do you want to send this note to 4D EMR? You will no longer be able to edit it in Knowtex if you do. \n\n" +
            "Notes submitted same day are sent immediately. Notes submitted on subsequent days are synced twice daily at 7AM and 7PM. \n\n" +
            "Click OK to send the note to 4D EMR \n" +
            "Click Cancel to continue editing in Knowtex and send the note to 4D EMR later"
        );
        setSendTo4D(confirmed);
      }

      if (
        org === "cCARE" ||
        org === "Astera" ||
        org === "Astera Rheumatology" ||
        org === "Astera Breast Surgery" ||
        org === "Astera Pall Care" ||
        org === "astera urology" ||
        org === "Solara" ||
        org === "PCSRI" ||
        org === "PCSRI_Rittenhouse" ||
        org === "LACN" ||
        org === "CCC" ||
        org === "MOASD_V2" ||
        org === "MOASD_V3" ||
        org === "LACN_Gastro"
      ) {
        const confirmed = window.confirm(
          "Do you want to send this note to OncoEMR? You will no longer be able to edit it in Knowtex if you do. \n\n" +
            "Click OK to send the note to OncoEMR \n" +
            "Click Cancel to continue editing in Knowtex and send the note to OncoEMR later"
        );
        setSendToOncoEMR(confirmed);
        if (confirmed) {
          getAccessTokenAndSendNote();
        }
      }

      if (org === "MedVanta") {
        const confirmed = window.confirm(
          "Do you want to send this note to ModMed? You will no longer be able to edit it in Knowtex if you do. \n\n" +
            "Click OK to send the note to ModMed \n" +
            "Click Cancel to continue editing in Knowtex and send the note to ModMed later"
        );
        setSendToModMed(confirmed);
        if (confirmed) {
          getAccessTokenAndSendNote();
        }
      }

      if (org === "LVOBGYN") {
        const confirmed = window.confirm(
          "Do you want to send this note to Athena? You will no longer be able to edit it in Knowtex if you do. \n\n" +
            "Click OK to send the note to Athena \n" +
            "Click Cancel to continue editing in Knowtex and send the note to Athena later"
        );
        setSendToAthena(confirmed);
        if (confirmed) {
          getAccessTokenAndSendNote();
        }
      }

      if (org === "VAAITechSprint" || org === "Knowtex") {
        const confirmed = window.confirm(
          "Do you want to send this note to your EHR? You will no longer be able to edit it in Knowtex if you do. \n\n" +
            "Click OK to send the note to EHR \n" +
            "Click Cancel to continue editing in Knowtex and send the note to EHR later"
        );
        setSendToEHR(confirmed);
        if (confirmed) {
          changeStatusToLocked();
        }
      }

      // logging
      // get user_id from token
      const decoded_token = jwt_decode(localStorage.token);
      const user_id = decoded_token["user"]["id"];
      // User clicked upload
      window.gtag("event", "user_save_soap_note", {
        app_name: "Knowtex-Webapp",
        screen_name: "Note Edit Page",
        job_name: user_id,
      });

      //user clicked upload to EHR
      window.gtag("event", "user_upload_note_to_EHR", {
        app_name: "Knowtex-Webapp",
        screen_name: "Note Edit Page",
        job_name: user_id,
      });

      // Define the event of when user clicked 'Save Changes'
      const saveNoteEvent = {
        env: process.env.REACT_APP_API_URL.includes("localhost")
          ? "local"
          : "prod",
        service: "knowtex-webApp",
        event: {
          eventName: "user_save_soap_note",
          userId: user_id,
          patientID: patient.patient_id,
          screenName: "Note Edit Page",
          timestamp: new Date().toISOString(),
        },
      };

      //Define event when user clicks 'Upload to EHR'
      const saveUploadEvent = {
        env: process.env.REACT_APP_API_URL.includes("localhost")
          ? "local"
          : "prod",
        service: "knowtex-webApp",
        event: {
          eventName: "user_upload_note_to_EHR",
          userId: user_id,
          patientID: patient.patient_id,
          screenName: "Note Edit Page",
          timestamp: new Date().toISOString(),
        },
      };

      sendEvent(saveNoteEvent, saveUploadEvent);
    } catch (err) {
      console.error(err.message);
    }
  };

  const hasUnspecifiedIronDeficiencyAnemia = () => {
    const combinedCodes = [...primaryCodes, ...secondaryCodes];

    // Check if any object’s .code property is exactly "D50.9" or "D64.9"
    return combinedCodes.some(
      (item) => item.code === "D50.9" || item.code === "D64.9"
    );
  };

  const getAccessTokenAndSendNote = async () => {
    changeEHRStatusToUploading();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/dashboard/getaccesstoken`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        }
      );

      if (!response.ok) {
        console.log("can't get access token " + response);
        alert(
          "Unable to verify credentials to upload note. Please try again or contact Knowtex support to resolve this"
        );
        changeStatusToReview();
        changeEHRStatusToIdle();
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      // Proceed to upload the note if access token is retrieved
      if (data.accessToken) {
        //console.log("access token " + data.accessToken);
        console.log("now about to send note");

        sendNoteViaCobalt(data.accessToken);
      } else {
        console.error("Access token not available.");
        alert(
          "Unable to verify credentials to upload note. Please try again or contact Knowtex support to resolve this"
        );
        changeStatusToReview();
        changeEHRStatusToIdle();
      }
    } catch (error) {
      console.error("Failed to fetch access token:", error);
      changeStatusToReview();
      changeEHRStatusToIdle();
    }
  };

  const sendNoteViaCobalt = async (accessToken) => {
    const formatIcd10Codes = (codes) => {
      // console.log("Initial codes type:", typeof codes);
      //console.log("Initial codes structure:", JSON.stringify(codes, null, 2));

      // If codes is already in the correct format with selected_codes at top level
      if (codes?.selected_codes) {
        console.log("Case 1: Input has selected_codes at top level");
        return codes;
      }

      // If codes has default structure, extract selected_codes
      if (codes?.default?.selected_codes) {
        console.log("Case 2: Input has default structure");
        return {
          selected_codes: codes.default.selected_codes,
        };
      }

      // If codes has just a codes array at top level, wrap it in selected_codes
      if (codes?.codes) {
        console.log(
          "Case 3: Input has codes array at top level, wrapping in selected_codes"
        );
        return {
          selected_codes: codes,
        };
      }

      // Fallback: return default structure with empty codes array
      console.log("Case 4: No matching structure found");
      return {
        selected_codes: {
          codes: [],
        },
      };
    };

    const apiUrl = "https://api.usecobalt.com/v1/notes";

    let bodyData;
    if (isCodingTestProvider) {
      // Remove the await since formatIcd10Codes is not async
      let icd10Codes = formatIcd10Codes(selectedCodes);
      bodyData = {
        appointment_id: patient.appointment_id,
        appointment_date: patient.date_of_appt,
        mrn: patient.mrn,
        note: note,
        icd_10_codes: icd10Codes,
      };
    } else {
      bodyData = {
        appointment_id: patient.appointment_id,
        appointment_date: patient.date_of_appt,
        mrn: patient.mrn,
        note: note,
      };
    }

    const headers = {
      "Content-Type": "application/json",
      client_id: "ci_live_RRG6m7sZGBWod2xfrhRkw4Ph",
      client_secret: "cs_live_iwoQAkjVRhao7xRbWLLdDUnu",
      access_token: accessToken,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(bodyData),
      });

      if (!response.ok) {
        const data = await response.json();
        console.log("Can't upload note: " + data.message);

        alert(
          "Unable to upload note at this time. Please try again later or contact Knowtex support to resolve this. Error details: " +
            data.message
        );
        if (
          data.message ===
          "Account link expired. Please go through the integration setup again."
        ) {
          redirectToSettings();
        }
        changeStatusToReview();
        changeEHRStatusToIdle();

        throw new Error(
          "Failed to send note, server response was not ok: " + data.message
        );
      }

      const responseData = await response.json();
      console.log("Response from Cobalt API:", responseData);
    } catch (error) {
      console.error("Error making request:", error);
      changeStatusToReview();
      changeEHRStatusToIdle();
    }
  };

  const handleSendTo4D = async () => {
    if (!sendTo4D) return;

    changeStatusToLocked();

    setSendTo4D(false);
  };

  useEffect(() => {
    handleSendTo4D();
  }, [sendTo4D]);

  const handleSendToOncoEMR = async () => {
    if (!sendToOncoEMR) return;

    changeStatusToLocked();

    setSendToOncoEMR(false);
  };

  useEffect(() => {
    handleSendToOncoEMR();
  }, [sendToOncoEMR]);

  const handleSendToAthena = async () => {
    if (!sendToAthena) return;

    changeStatusToLocked();

    setSendToAthena(false);
  };

  // swapped these so they don't conflict
  useEffect(() => {
    handleSendToModMed();
  }, [sendToModMed]);

  const handleSendToModMed = async () => {
    if (!sendToModMed) return;
    changeStatusToLocked();
    setSendToModMed(false);
  };

  useEffect(() => {
    handleSendToAthena();
  }, [sendToAthena]);

  const changeEHRStatusToUploading = async () => {
    try {
      const ehr_status = "uploading"; // Changed from ehrStatus to ehr_status
      const body = { ehr_status }; // Ensure the key matches the backend

      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", localStorage.token);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/notes/ehr_upload_status/${patient.patient_id}`,
        {
          method: "PUT",
          headers: myHeaders,
          body: JSON.stringify(body),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Failed to update EHR status:", errorText);
        //alert("Failed to update EHR status. Please try again.");
        return;
      }

      console.log("EHR Upload Status changed to 'uploading'");
    } catch (err) {
      console.error("Error in changeEHRStatusToUploading:", err.message);
      alert("An error occurred while updating EHR status.");
    }
  };

  const changeEHRStatusToIdle = async () => {
    try {
      const ehr_status = "idle"; // Changed from ehrStatus to ehr_status
      const body = { ehr_status }; // Ensure the key matches the backend

      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", localStorage.token);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/notes/ehr_upload_status/${patient.patient_id}`,
        {
          method: "PUT",
          headers: myHeaders,
          body: JSON.stringify(body),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Failed to update EHR status:", errorText);
        //alert("Failed to update EHR status. Please try again.");
        return;
      }

      console.log("EHR Upload Status changed to 'idle'");
    } catch (err) {
      console.error("Error in changeEHRStatusToIdle:", err.message);
      alert("An error occurred while updating EHR status.");
    }
  };

  const changeStatusToLocked = async () => {
    try {
      const status = "Locked";
      const body = { status };

      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", localStorage.token);

      await fetch(
        `${process.env.REACT_APP_API_URL}/notes/status/${patient.patient_id}`,
        {
          method: "PUT",
          headers: myHeaders,
          body: JSON.stringify(body),
        }
      );
      //console.log("Status changed");
    } catch (err) {
      console.error(err.message);
    }
  };

  const changeStatusToReview = async () => {
    try {
      const status = "Review";
      //console.log(status);
      const body = { status };

      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", localStorage.token);

      await fetch(
        `${process.env.REACT_APP_API_URL}/notes/status/${patient.patient_id}`,
        {
          method: "PUT",
          headers: myHeaders,
          body: JSON.stringify(body),
        }
      );
      //console.log("Status changed");
    } catch (err) {
      console.error(err.message);
    }
  };

  // Function to send the defined even to aws
  function sendEvent(userEvent) {
    // Replace "http://example.com/api/events" with the actual endpoint URL.
    const endpointUrl =
      "https://tpv4xmll56.execute-api.us-east-1.amazonaws.com/prod/doctorservice";

    axios
      .post(endpointUrl, userEvent)
      .then((response) => {
        //console.log("Event sent successfully:", response.data);
        //console.log("Event: %v",userEvent);
      })
      .catch((error) => {
        //console.error("Failed to send event:", error);
      });
  }

  // Function to send log when user clicked 'Review Note'
  function log_review_note(patient_id) {
    // get user_id from token
    const decoded_token = jwt_decode(localStorage.token);
    const user_id = decoded_token["user"]["id"];

    const reviewNoteEvent = {
      env: process.env.REACT_APP_API_URL.includes("localhost")
        ? "local"
        : "prod",
      service: "knowtex-webApp",
      event: {
        eventName: "user_reviewed_soap_note",
        userId: user_id,
        patientID: patient_id,
        screenName: "Note Edit Page",
        timestamp: new Date().toISOString(),
      },
    };
    // send the event
    sendEvent(reviewNoteEvent);
  }

  // function to send log and metrics when user clicks icd10 code button
  function code_click(patient_id) {
    // get user_id from token
    const decoded_token = jwt_decode(localStorage.token);
    const user_id = decoded_token["user"]["id"];

    const icdEvent = {
      env: process.env.REACT_APP_API_URL.includes("localhost")
        ? "local"
        : "prod",
      service: "knowtex-webApp",
      event: {
        eventName: "user_clicked_icd10_code",
        userId: user_id,
        patientID: patient_id,
        screenName: "Note Edit Page",
        timestamp: new Date().toISOString(),
      },
    };
    // send event
    sendEvent(icdEvent);
  }

  // function to send log and metrics when user clicks coding tab
  function coding_tab_click(patient_id) {
    // get user_id from token
    const decoded_token = jwt_decode(localStorage.token);
    const user_id = decoded_token["user"]["id"];

    const codingTabEvent = {
      env: process.env.REACT_APP_API_URL.includes("localhost")
        ? "local"
        : "prod",
      service: "knowtex-webApp",
      event: {
        eventName: "user_clicked_coding_tab",
        userId: user_id,
        patientID: patient_id,
        screenName: "Note Edit Page",
        timestamp: new Date().toISOString(),
      },
    };
    // send event
    sendEvent(codingTabEvent);
  }

  // function to send logs and metrics for when user is editing/modifying icd-10 codes.
  function modifying_codes_click(patient_id) {
    // get user_id from token
    const decoded_token = jwt_decode(localStorage.token);
    const user_id = decoded_token["user"]["id"];

    const modifyingCodeEvent = {
      env: process.env.REACT_APP_API_URL.includes("localhost")
        ? "local"
        : "prod",
      service: "knowtex-webApp",
      event: {
        eventName: "user_modifying_codes",
        userId: user_id,
        patientID: patient_id,
        screenName: "Note Edit Page",
        timestamp: new Date().toISOString(),
      },
    };
    // send event
    sendEvent(modifyingCodeEvent);
  }

  async function onCopy(patient_id) {
    setIsCopied(true);
    const decoded_token = jwt_decode(localStorage.token);
    const user_id = decoded_token["user"]["id"];
    const copyNoteEvent = {
      env: process.env.REACT_APP_API_URL.includes("localhost")
        ? "local"
        : "prod",
      service: "knowtex-webApp",
      event: {
        eventName: "user_clicked_copy",
        userId: user_id,
        patientID: patient_id,
        screenName: "Note Edit Page",
        timestamp: new Date().toISOString(),
      },
    };

    sendEvent(copyNoteEvent);
  }

  const formatICD10Codes = (icd10Data) => {
    if (!icd10Data) return "";

    // Check if selected_codes is a string. If so, parse it.
    let selectedCodesInit;
    if (typeof icd10Data.selected_codes === "string") {
      try {
        selectedCodesInit = JSON.parse(icd10Data.selected_codes);
      } catch (err) {
        console.error("Failed to parse selected_codes JSON:", err);
        return { icd10Data };
      }
    } else {
      selectedCodesInit = icd10Data.selected_codes;
    }

    // Ensure codes is an array
    if (!selectedCodesInit || !Array.isArray(selectedCodesInit.codes)) {
      return { icd10Data };
    }
    console.log("selectedCodesInit", selectedCodesInit);
    return selectedCodesInit;
  };

  const [description, setDescription] = useState("");
  const [note, setNote] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [codingInfo, setCodingInfo] = useState("");
  const [LOSCode, setLOSCode] = useState("");
  const [encounterTime, setEncounterTime] = useState("");
  const [timeBasedCode, setTimeBasedCode] = useState("");
  const [icd_10_codes, setICD_10_codes] = useState("");
  const [orders, setOrders] = useState("");
  const [patientData, setPatientData] = useState({});
  const [selectedCodes, setSelectedCodes] = useState({});
  const [primaryCodes, setPrimaryCodes] = useState([]);
  const [secondaryCodes, setSecondaryCodes] = useState([]);

  //stores the original icd10 codes json with default and selected
  const [originalCodes, setOriginalCodes] = useState({});

  //state variable to keep track of if alternative or similar codes modal will show up.
  const [showAlternativeCodes, setShowAlternativeCodes] = useState(false);

  useEffect(() => {
    setPrimaryCodes(
      selectedCodes?.codes?.filter((code) => code.codeType === "Primary") || []
    );
    setSecondaryCodes(
      selectedCodes?.codes?.filter((code) => code.codeType === "Secondary") ||
        []
    );
  }, [selectedCodes]);

  //function to check if the primary code is iron deficiency anemia, unspecified
  const isUnspecifiedAnemia = (codeName) => {
    if (
      codeName === "Iron deficiency anemia, unspecified" ||
      codeName === "Anemia, unspecified"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const saveSelectedCodes = async (newSelectedCodes) => {
    try {
      // newSelectedCodes should have the shape { codes: [...] }
      // not { default: {...}, selected_codes: {...} }
      // if your server expects just an object with "codes".
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/notes/todos/icd10/${patient.patient_id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
          body: JSON.stringify(newSelectedCodes), // => { codes: [...] }
        }
      );

      if (!response.ok) {
        throw new Error(`Error saving selected codes: ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Successfully saved selected codes:", data);
      //setSelectedCodes(data.selected_codes);
    } catch (error) {
      console.error("Failed to save selected codes:", error);
    }
  };

  const [diarizedTranscript, setDiarizedTranscript] = useState(
    patient.diarized_transcript
  );
  const [diarizedTranscriptCsvUrl, setDiarizedTranscriptCsvUrl] = useState(
    patient.diarized_transcript_csv_url
  );

  const [hcc_codes, setHCC_Codes] = useState(patient.hcc_codes);
  const [cpt_codes, setCPT_codes] = useState(patient.cpt_codes);

  const quillRef = useRef();

  const isLocked = patient.status === "Locked";

  const convertPlainTextToHtml = (text) => {
    // Check if text is truthy before attempting to replace
    return text ? text.replace(/\n/g, "<br>") : "";
  };
  const responseHtml = convertPlainTextToHtml(note);

  // const quillModules = {
  //   toolbar: [
  //     [{ header: [1, 2, 3, false] }],
  //     ["bold", "italic", "underline", "strike"],
  //     [{ list: "ordered" }, { list: "bullet" }],
  //     [{ align: [] }],
  //     [{ color: [] }, { background: [] }],
  //     ["clean"],
  //   ],
  // };

  //useEffect to handle csv download
  useEffect(() => {
    setDiarizedTranscriptCsvUrl(patient.diarized_transcript_csv_url);
  }, [patient.diarized_transcript_csv_url]);

  const handleTranscriptDownload = () => {
    if (diarizedTranscriptCsvUrl) {
      //create anchor element
      const link = document.createElement("a");
      link.href = diarizedTranscriptCsvUrl;

      // set download attribute with csv file name
      link.download = `${diarizedTranscriptCsvUrl}`;

      // append anchor element to body
      document.body.appendChild(link);

      //trigger click event
      link.click();

      //clean up
      document.body.removeChild(link);
    }
  };

  const [codesCount, setCodesCount] = useState(0);
  const [ordersCount, setOrdersCount] = useState(0);

  //useEffect to handle csv download
  useEffect(() => {
    setCodesCount(icd_10_codes?.match(/([A-Z]\d+\.\d+)/g)?.length);
  }, [icd_10_codes]);

  useEffect(() => {
    setOrdersCount(orders?.match(/([t][e][s][t]_[n][a][m][e])/g)?.length);
  }, [orders]);

  //delete modal state variables
  const [showDelete, setShowDelete] = useState(false);

  // Track which code we're deleting
  const [codeToDelete, setCodeToDelete] = useState(null);

  // EditPatient.jsx (snippet)
  const handleDeleteCode = (codeObject) => {
    if (!codeObject) return;
    // Remove from local state, but do NOT call saveSelectedCodes here
    const updatedCodes = selectedCodes.codes.filter(
      (c) => c.code !== codeObject.code
    );
    setSelectedCodes({ ...selectedCodes, codes: updatedCodes });
    setShowDelete(false);
  };

  //function to determine if suggested codes or alternative modals show up
  const checkSimilarOrAlternative = (code) => {
    if (code.alternatives) {
      setShowAlternativeCodes(true);
    } else if (code.similar) {
      setShowSuggestions(true);
    } else {
      setShowAlternativeCodes(false);
      setShowSuggestions(false);
    }
  };

  return (
    <Fragment>
      <div
        role="edit-patient"
        className="flex items-center hover:text-secondary-text-grey"
      >
        <div className="pr-2">
          <svg
            className="w-6 h-6 text-background1"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 4h3c.6 0 1 .4 1 1v15c0 .6-.4 1-1 1H6a1 1 0 01-1-1V5c0-.6.4-1 1-1h3m0 3h6m-6 7l2 2 4-4m-5-9v4h4V3h-4z"
            />
          </svg>
        </div>

        {/* Here we show "Review" or "Locked" plus (Added) sub-status if uploading or uploaded */}
        <button
          type="button"
          className={`text-xl ${
            status === "Review" ? "" : "flex items-center text-ktx-red"
          }`}
          data-bs-toggle="modal"
          data-bs-target={`#id${patient.patient_id}`}
          onClick={() => {
            setDescription(patient.description);
            setNote(patient.note);
            setPatientData(patientData);
            setCodingInfo(patient.coding_information);
            setLOSCode(patient.los_code);
            log_review_note(patient.patient_id);
            setEncounterTime(patient.time_spent);
            setTimeBasedCode(patient.time_based_code);
            setICD_10_codes(patient.icd_10_codes);
            setOrders(patient.orders);
            setIsEditing(status !== "Locked");
            setDiarizedTranscript(patient.diarized_transcript);
            setDiarizedTranscriptCsvUrl(patient.diarized_transcript_csv_url);
            setCPT_codes(patient.cpt_codes);
            setHCC_Codes(patient.hcc_codes);
            setSelectedCodes(formatICD10Codes(patient.icd10_codes_v2));
            setOriginalCodes(patient.icd10_codes_v2);
          }}
        >
          {status === "Review" ? (
            "Review"
          ) : (
            <div>
              Locked (View Only)
              {ehrUploadStatus === "uploading" && (
                <div className="text-white text-sm">Uploading to EHR...</div>
              )}
              {ehrUploadStatus === "success" && (
                <div className="text-white text-sm">Uploaded to EHR</div>
              )}
            </div>
          )}
        </button>
      </div>

      <div
        className="modal fade modal-xl h-full"
        id={`id${patient.patient_id}`}
        tabIndex="-1"
        aria-labelledby="editModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        role="edit-modal"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="text-accent1 text-3xl font-bold"
                id="editModalLabel"
              >
                Review {patient.description}'s Note
              </h1>
              <button
                type="button"
                className="mt-0 mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setDescription(patient.description);
                  setNote(patient.note);
                  setCodingInfo(patient.coding_information);
                  setLOSCode(patient.los_code);
                  log_review_note(patient.patient_id);
                  setEncounterTime(patient.time_spent);
                  setTimeBasedCode(patient.time_based_code);
                  setICD_10_codes(patient.icd_10_codes);
                  setOrders(patient.orders);
                  setPatientData(patientData);
                  setIsCopied(false);
                  // set isEditing back to false on close button
                  setIsEditing(false);
                  setDiarizedTranscript(patient.diarized_transcript);
                  setDiarizedTranscriptCsvUrl(
                    patient.diarized_transcript_csv_url
                  );
                  setCPT_codes(patient.cpt_codes);
                  setHCC_Codes(patient.hcc_codes);
                  setSelectedCodes(selectedCodes);
                }}
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18 17.94 6M18 18 6.06 6"
                  />
                </svg>
              </button>
            </div>
            <div className="modal-body ">
              <div>
                <nav>
                  <div
                    className="text-black flex mb-0 whitespace-nowrap overflow-x-auto "
                    id={`id${patient.patient_id}nav-tab`}
                    role="tablist"
                  >
                    <button
                      className=" nav-link active border-light-grey pt-2 pl-11 pr-11 pb-2 mr-4  bg-secondary-grey rounded-t-md focus:bg-accent1 focus:text-background1 text-accent1 font-bold"
                      id={`id${patient.patient_id}nav-home-tab`}
                      data-bs-toggle="tab"
                      data-bs-target={`#id${patient.patient_id}nav-home`}
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      Note
                    </button>
                    <button
                      className="nav-link border pt-2 pl-11 pr-11 pb-2 mr-4 rounded-t-md  focus:bg-accent1 focus:text-background1 text-accent1 bg-secondary-grey font-bold"
                      id={`id${patient.patient_id}nav-profile-tab`}
                      data-bs-toggle="tab"
                      data-bs-target={`#id${patient.patient_id}nav-profile`}
                      type="button"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                      onClick={coding_tab_click(patient.patient_id)}
                    >
                      {/* Codes ({codesCount ? codesCount : 0}) */}
                      Codes
                    </button>
                    <button
                      className="nav-link border pt-2 pl-11 pr-11 pb-2 mr-4 rounded-t-md focus:bg-accent1 focus:text-background1 text-accent1  bg-secondary-grey font-bold"
                      id={`id${patient.patient_id}nav-orders-tab`}
                      data-bs-toggle="tab"
                      data-bs-target={`#id${patient.patient_id}nav-orders`}
                      type="button"
                      role="tab"
                      aria-controls="nav-orders"
                      aria-selected="false"
                    >
                      Orders ({ordersCount ? ordersCount : 0})
                    </button>
                    {/* TODO: remove the cCare conditional, just having it here so it renders. */}

                    <button
                      className="nav-link border pt-2 pl-11 pr-11 pb-2 mr-4 rounded-t-md focus:bg-accent1 focus:text-background1 text-accent1  bg-secondary-grey font-bold"
                      id={`id${patient.patient_id}nav-diarized-tab`}
                      data-bs-toggle="tab"
                      data-bs-target={`#id${patient.patient_id}nav-diarized`}
                      type="button"
                      role="tab"
                      aria-controls="nav-diarized"
                      aria-selected="false"
                    >
                      Transcript
                    </button>
                  </div>
                </nav>
                <div
                  className="tab-content p-4 border-t-2 border-t-accent1 border-x-2  border-x-transparent-grey"
                  id="nav-tabContent"
                >
                  <div
                    className="tab-pane fade show active"
                    id={`id${patient.patient_id}nav-home`}
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    <div className="float-end d-flex mt-3 mb-3">
                      <CopyToClipboard
                        // text={responseHtml || ""}
                        // options={{ format: "text/html" }}
                        text={note}
                        onCopy={() => onCopy(patient.patient_id)}
                      >
                        {isCopied ? (
                          <div className="text-success">Copied</div>
                        ) : (
                          <div className="flex ">
                            <img
                              src={clipboard}
                              width="20"
                              height="20"
                              alt="copy"
                              className="mr-1 hover:cursor-pointer"
                            />
                            <h1 className="text-accent1 hover:cursor-pointer">
                              Copy Note
                            </h1>
                          </div>
                        )}
                      </CopyToClipboard>
                    </div>
                    <div className="mt-5">
                      <textarea
                        type="text"
                        readOnly={status === "Locked"} // Apply readOnly based on status
                        className="form-control resize-none rounded-md border-0 shadow-knowtex-shadow text-accent1 bg-secondary-grey"
                        value={note || ""}
                        onChange={(e) => {
                          setNote(e.target.value);
                          setIsCopied(false);
                        }}
                        rows="30"
                      ></textarea>
                      {isCodingTestProvider && (
                        <>
                          {/* New ICD 10 Codes Section */}
                          <div className="flex flex-col mt-3">
                            <h1 className=" text-accent1 text-xl font-medium mb-2">
                              ICD-10 Codes
                            </h1>
                            <div className="flex flex-row space-x-60">
                              {/* Primary Codes */}
                              <div className="flex flex-col items-start">
                                <div className="flex flex-row items-start">
                                  <h2 className=" text-accent1 text-lg font-bold mb-2 mr-4">
                                    Primary Codes
                                  </h2>
                                  <div
                                    className="flex flex-row items-center cursor-pointer"
                                    onClick={() => {
                                      setShowAddCode(true);
                                      setIsPrimaryCode(true);
                                      setIsSecondaryCode(false);
                                      modifying_codes_click(patient.patient_id);
                                    }}
                                  >
                                    <svg
                                      className="w-6 h-6 text-ktx-green"
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      fill="currentColor"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4.243a1 1 0 1 0-2 0V11H7.757a1 1 0 1 0 0 2H11v3.243a1 1 0 1 0 2 0V13h3.243a1 1 0 1 0 0-2H13V7.757Z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                    <text className="text-accent1 text-sm ml-1">
                                      Add Code
                                    </text>
                                  </div>
                                </div>
                                {/* Render Primary Codes */}
                                {primaryCodes.map((code, index) => (
                                  <div
                                    className="flex flex-row items-center space-x-2"
                                    key={code.code}
                                  >
                                    <button
                                      type="button"
                                      className={`${
                                        isUnspecifiedAnemia(code.name)
                                          ? "bg-transparent-red text-ktx-red  hover:bg-ktx-red hover:text-white"
                                          : "bg-transparent-purple disabled:hover:cursor-not-allowed "
                                      } text-accent1 rounded-2xl font-bold text-lg w-fit text-left p-3 mb-3 disabled:hover:cursor-not-allowed`}
                                      onClick={() => {
                                        setCurrentSelectedCode(code);
                                        code_click(patient.patient_id);
                                        isUnspecifiedAnemia(code.name)
                                          ? setShowAlternativeCodes(true)
                                          : checkSimilarOrAlternative(code);
                                        modifying_codes_click(
                                          patient.patient_id
                                        );
                                      }}
                                    >
                                      {index + 1}. {code.name}
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        // 1) set the code you plan to delete
                                        setCodeToDelete(code);
                                        console.log(code);
                                        // 2) open the modal
                                        setShowDelete(true);
                                      }}
                                    >
                                      <svg
                                        className="w-6 h-6 text-transparent-red hover:text-ktx-red cursor-pointer mb-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          stroke="currentColor"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                        />
                                      </svg>
                                    </button>
                                    {showAlternativeCodes && (
                                      <AlternativeCodeModal
                                        code={currentSelectedCode}
                                        setShowAlternativeCodes={
                                          setShowAlternativeCodes
                                        }
                                        isUnspecifiedAnemia={
                                          isUnspecifiedAnemia
                                        }
                                        selectedCodes={selectedCodes} // pass parent's codes
                                        setSelectedCodes={setSelectedCodes} // pass parent's setter
                                        setCodesChange={setCodesChange}
                                        patientID={patient.patient_id}
                                      />
                                    )}
                                  </div>
                                ))}
                              </div>

                              {/* Secondary Codes */}
                              <div className="flex flex-col">
                                <div className="flex flex-row items-start">
                                  <h2 className=" text-accent1 text-lg font-bold mb-2 mr-4">
                                    Secondary Codes
                                  </h2>
                                  <div
                                    className="flex flex-row items-center cursor-pointer"
                                    onClick={() => {
                                      modifying_codes_click(patient.patient_id);
                                      setShowAddCode(true);
                                      setIsSecondaryCode(true);
                                      setIsPrimaryCode(false);
                                    }}
                                  >
                                    <svg
                                      className="w-6 h-6 text-ktx-green"
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      fill="currentColor"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4.243a1 1 0 1 0-2 0V11H7.757a1 1 0 1 0 0 2H11v3.243a1 1 0 1 0 2 0V13h3.243a1 1 0 1 0 0-2H13V7.757Z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                    <text className="text-accent1 text-sm ml-1">
                                      Add Code
                                    </text>
                                  </div>
                                </div>

                                <div className="flex flex-col">
                                  {/* Render Secondary Codes */}
                                  {secondaryCodes.map((code, index) => (
                                    <div
                                      className="flex flex-row items-center space-x-2"
                                      key={code.code}
                                    >
                                      <button
                                        type="button"
                                        className={`${
                                          isUnspecifiedAnemia(code.name)
                                            ? "bg-transparent-red text-ktx-red  hover:bg-ktx-red hover:text-white"
                                            : "bg-transparent-purple "
                                        } text-accent1 rounded-2xl font-bold text-lg w-fit text-left p-3 mb-3  `}
                                        onClick={() => {
                                          isUnspecifiedAnemia(code.name)
                                            ? setShowAlternativeCodes(true)
                                            : checkSimilarOrAlternative(code);
                                          setCurrentSelectedCode(code);
                                          code_click(patient.patient_id);
                                          modifying_codes_click(
                                            patient.patient_id
                                          );
                                        }}
                                      >
                                        {index + 1}. {code.name}
                                      </button>
                                      <button
                                        type="button"
                                        onClick={() => {
                                          // 1) set the code you plan to delete
                                          setCodeToDelete(code);
                                          console.log(code);
                                          // 2) open the modal
                                          setShowDelete(true);
                                        }}
                                      >
                                        <svg
                                          className="w-6 h-6 text-transparent-red hover:text-ktx-red cursor-pointer mb-3"
                                          aria-hidden="true"
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                        >
                                          <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                          />
                                        </svg>
                                      </button>
                                      {showSuggestions && (
                                        <CodeSuggestionsModal
                                          setShowSuggestions={
                                            setShowSuggestions
                                          }
                                          selectedCode={currentSelectedCode}
                                          selectedCodes={selectedCodes}
                                          setSelectedCodes={setSelectedCodes}
                                          setCodesChange={setCodesChange}
                                          patientID={patient.patient_id}
                                        />
                                      )}
                                    </div>
                                  ))}

                                  {showAddCode && (
                                    <AddCodeModal
                                      showAddCode={showAddCode}
                                      setShowAddCode={setShowAddCode}
                                      setCodesChange={setCodesChange}
                                      selectedCodes={selectedCodes} // If you want to append
                                      setSelectedCodes={setSelectedCodes}
                                      setIsPrimaryCode={setIsPrimaryCode}
                                      setIsSecondaryCode={setIsSecondaryCode}
                                      isPrimaryCode={isPrimaryCode}
                                      isSecondaryCode={isSecondaryCode}
                                    />
                                  )}

                                  {showDelete && (
                                    <DeleteCodeModal
                                      setShowDelete={setShowDelete}
                                      codeToDelete={codeToDelete}
                                      handleDeleteCode={handleDeleteCode}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {/* <ReactQuill
                        theme="snow"
                        value={responseHtml || ""}
                        onChange={(newNote) => {
                          setNote(newNote);
                          setIsCopied(false);
                        }}
                        className="text-accent1 "
                        modules={quillModules}
                        ref={quillRef}
                        formats={[
                          "header",
                          "bold",
                          "italic",
                          "underline",
                          "strike",
                          "list",
                          "bullet",
                          "align",
                          "color",
                          "background",
                        ]}
                        readOnly={isLocked}
                      /> */}
                      {/* {name === "Dr. Ketonis" ? (
                        <KetonisRTE />
                      ) : (
                        <ReactQuill
                          theme="snow"
                          value={responseHtml || ""}
                          onChange={(newNote) => {
                            setNote(newNote);
                            setIsCopied(false);
                          }}
                          className="text-accent1 "
                          modules={quillModules}
                          ref={quillRef}
                          formats={[
                            "header",
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "list",
                            "bullet",
                            "align",
                            "color",
                            "background",
                          ]}
                        />
                      )} */}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade text-accent1 text-xl font-bold"
                    id={`id${patient.patient_id}nav-profile`}
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                  >
                    {/* ICD-10 Codes
                    <textarea
                      type="text"
                      readOnly={status === "Locked"}
                      className="form-control resize-none rounded-md border-0 mb-4 mt-2 shadow-knowtex-shadow bg-secondary-grey"
                      value={icd_10_codes || ""}
                      onChange={(e) => setICD_10_codes(e.target.value)}
                      rows="10"
                    ></textarea> */}
                    E&M Code (based on MDM)
                    <input
                      type="text"
                      readOnly={status === "Locked"}
                      className="form-control mb-4 mt-2 rounded-md border-0 shadow-knowtex-shadow bg-secondary-grey"
                      value={LOSCode || ""}
                      onChange={(e) => setLOSCode(e.target.value)}
                    ></input>
                    Medical Decision Making Analysis
                    <textarea
                      type="text"
                      readOnly={status === "Locked"}
                      className="form-control resize-none mb-4 mt-2 rounded-md border-0 shadow-knowtex-shadow bg-secondary-grey"
                      value={codingInfo || ""}
                      onChange={(e) => setCodingInfo(e.target.value)}
                      rows="30"
                    ></textarea>
                    Total Encounter Time
                    <input
                      type="text"
                      readOnly={status === "Locked"}
                      className="form-control mb-4 mt-2 rounded-md border-0 shadow-knowtex-shadow bg-secondary-grey"
                      value={Math.floor(encounterTime) || ""} //convert from secs to min
                      onChange={(e) => setEncounterTime(e.target.value)}
                    ></input>
                    E&M Code (based on Time)
                    <input
                      type="text"
                      readOnly={status === "Locked"}
                      className="form-control mb-4 mt-2 rounded-md border-0 shadow-knowtex-shadow bg-secondary-grey"
                      value={timeBasedCode || ""}
                      onChange={(e) => setTimeBasedCode(e.target.value)}
                    ></input>
                    HCC Codes
                    <textarea
                      type="text"
                      readOnly={status === "Locked"}
                      className="form-control resize-none mb-4 mt-2 rounded-md border-0 shadow-knowtex-shadow bg-secondary-grey"
                      value={hcc_codes || ""}
                      onChange={(e) => setHCC_Codes(e.target.value)}
                      rows="30"
                    ></textarea>
                    CPT Codes
                    <textarea
                      type="text"
                      readOnly={status === "Locked"}
                      className="form-control resize-none mb-4 mt-2 rounded-md border-0 shadow-knowtex-shadow bg-secondary-grey"
                      value={cpt_codes || ""}
                      onChange={(e) => setCPT_codes(e.target.value)}
                      rows="30"
                    ></textarea>
                    <p></p>
                  </div>
                  <div
                    className="tab-pane fade text-accent1 text-xl font-bold"
                    id={`id${patient.patient_id}nav-orders`}
                    role="tabpanel"
                    aria-labelledby="nav-orders-tab"
                  >
                    Orders
                    <textarea
                      type="text"
                      readOnly={status === "Locked"} // Apply readOnly based on status
                      className="form-control resize-none mb-4 mt-2 rounded-md border-0 shadow-knowtex-shadow bg-secondary-grey"
                      value={orders || ""}
                      onChange={(e) => setOrders(e.target.value)}
                      rows="10"
                    ></textarea>
                  </div>
                  <div
                    className="tab-pane fade text-accent1 text-xl font-bold "
                    id={`id${patient.patient_id}nav-diarized`}
                    role="tabpanel"
                    aria-labelledby="nav-diarized=transcript-tab"
                  >
                    {/* <button
                        className="float-end mb-3 rounded-lg  text-accent1 p-2 text-sm mr-5 flex items-center"
                        onClick={handleTranscriptDownload}
                        disabled={!diarizedTranscriptCsvUrl}
                      >
                        <svg
                          class="w-6 h-6 text-accent1 dark:text-white mr-1"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01"
                          />
                        </svg>
                        Download Transcript CSV
                      </button> */}
                    Transcript
                    <textarea
                      type="text"
                      readOnly={status === "Locked"}
                      className="form-control resize-none mb-4 mt-2 rounded-md border-0 shadow-knowtex-shadow bg-secondary-grey"
                      value={diarizedTranscript || ""}
                      rows={30}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {status === "Review" ? generateRecordLink(patient) : null}

              {status === "Review" ? (
                <button
                  type="button"
                  className="bg-transparent-grey text-knowtex-grey pl-10 pr-10 pt-2 pb-2 rounded-md font-medium mr-4"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setDescription(patient.description);
                    setNote(patient.note);
                    setCodingInfo(patient.coding_information);
                    setLOSCode(patient.los_code);
                    setPatientData(patientData);
                    setSelectedCodes(selectedCodes);
                    log_review_note(patient.patient_id);
                    setEncounterTime(patient.time_spent);
                    setTimeBasedCode(patient.time_based_code);
                    setICD_10_codes(patient.icd_10_codes);
                    setOrders(patient.orders);
                    setIsCopied(false);
                    setDiarizedTranscript(patient.diarized_transcript);
                    setDiarizedTranscriptCsvUrl(
                      patient.diarized_transcript_csv_url
                    );

                    // set isEditing back to false on close button
                    setIsEditing(false);
                  }}
                >
                  Cancel
                </button>
              ) : null}

              {showUnspecifiedAlert && (
                <div
                  className="modal fade show"
                  style={{ display: "block" }}
                  tabIndex="-1"
                  aria-modal="true"
                  role="dialog"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title text-accent1">
                          Unspecified Code Found
                        </h5>
                        <button
                          type="button"
                          className="close"
                          aria-label="Close"
                          onClick={() => setShowUnspecifiedAlert(false)}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body text-accent1">
                        <p>
                          You have “anemia, unspecified” as one of your codes.
                          Please review this code and select a more specific
                          code to upload this note to OncoEMR.
                        </p>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            // If you want them to directly jump to the code tab or highlight the code,
                            // handle that logic here. For example, switch tabs or highlight the code field.

                            setShowUnspecifiedAlert(false);
                          }}
                        >
                          OK
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {(org === "cCARE" ||
                org === "Astera" ||
                org === "Astera Rheumatology" ||
                org === "Astera Breast Surgery" ||
                org === "Astera Pall Care" ||
                org === "astera urology" ||
                org === "Solara" ||
                org === "PCSRI" ||
                org === "PCSRI_Rittenhouse" ||
                org === "LACN" ||
                org === "CCC" ||
                org === "MOASD_V3" ||
                org === "MOASD_V2" ||
                org === "LACN_Gastro") &&
                patient.status !== "Locked" && (
                  <div className="relative group">
                    <button
                      type="button"
                      disabled={patient.status === "Locked"}
                      className={`${
                        isCodingTestProvider
                          ? hasUnspecifiedIronDeficiencyAnemia()
                            ? "bg-gray-300 text-gray-700 cursor-not-allowed rounded-lg px-4 py-2 mr-1"
                            : css.oncobutton
                          : css.oncobutton
                      } font-bold w-fit`}
                      data-bs-dismiss={
                        isCodingTestProvider
                          ? !hasUnspecifiedIronDeficiencyAnemia()
                            ? "modal"
                            : undefined
                          : "modal"
                      }
                      onClick={() => {
                        // If it's one of the special providers
                        if (isCodingTestProvider) {
                          // Then do the check for unspecified anemia
                          if (hasUnspecifiedIronDeficiencyAnemia()) {
                            setShowUnspecifiedAlert(true);
                            console.log("Unspecified code found");
                          } else {
                            // Proceed normally
                            editText(patient.patient_id);
                            setIsCopied(false);
                            setIsEditing(false);
                          }
                        } else {
                          // If not a special provider, always proceed normally
                          editText(patient.patient_id);
                          setIsCopied(false);
                          setIsEditing(false);
                        }
                      }}
                    >
                      Upload to EMR
                    </button>
                  </div>
                )}

              {org === "4D EMR" && patient.status !== "Locked" && (
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  className={css.oncobutton}
                  onClick={() => {
                    editText(patient.patient_id);
                    //uploadToCCare();
                    setIsCopied(false);
                    // set isEditing back to false on close button
                    setIsEditing(false);
                  }}
                  disabled={patient.status === "Locked"}
                >
                  Upload to EMR
                </button>
              )}

              {(org === "VAAITechSprint" || org === "Knowtex") &&
                patient.status !== "Locked" && (
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    className={css.oncobutton}
                    onClick={() => {
                      editText(patient.patient_id);
                      //uploadToCCare();
                      setIsCopied(false);
                      // set isEditing back to false on close button
                      setIsEditing(false);
                    }}
                    disabled={patient.status === "Locked"}
                  >
                    Upload to EMR
                  </button>
                )}

              {org === "LVOBGYN" && patient.status !== "Locked" && (
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  className={css.oncobutton}
                  onClick={() => {
                    editText(patient.patient_id);
                    //uploadToCCare();
                    setIsCopied(false);
                    // set isEditing back to false on close button
                    setIsEditing(false);
                  }}
                  disabled={patient.status === "Locked"}
                >
                  Upload to EMR
                </button>
              )}

              {org === "MedVanta" && patient.status !== "Locked" && (
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  className={css.oncobutton}
                  onClick={() => {
                    editText(patient.patient_id);
                    //uploadToCCare();
                    setIsCopied(false);
                    // set isEditing back to false on close button
                    setIsEditing(false);
                  }}
                  disabled={patient.status === "Locked"}
                >
                  Upload to EMR
                </button>
              )}

              {status === "Review" ? (
                <button
                  type="button"
                  className={css.save}
                  data-bs-dismiss={
                    // Only set "modal" if there is NO unspecified anemia
                    !hasUnspecifiedIronDeficiencyAnemia() ? "modal" : undefined
                  }
                  onClick={() => {
                    // If it's one of the special providers
                    if (isCodingTestProvider) {
                      // Then do the check for unspecified anemia
                      if (hasUnspecifiedIronDeficiencyAnemia()) {
                        setShowUnspecifiedAlert(true);
                        console.log("Unspecified code found");
                      } else {
                        // Proceed normally
                        editText(patient.patient_id);
                        setIsCopied(false);
                        setIsEditing(false);
                      }
                    } else {
                      // If not a special provider, always proceed normally
                      editText(patient.patient_id);
                      setIsCopied(false);
                      setIsEditing(false);
                    }
                  }}
                  disabled={patient.status === "Locked"}
                >
                  Save Changes
                </button>
              ) : (
                <button
                  type="button"
                  className={css.save}
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setDescription(patient.description);
                    setNote(patient.note);
                    setPatientData(patientData);
                    setSelectedCodes(selectedCodes);
                    setCodingInfo(patient.coding_information);
                    setLOSCode(patient.los_code);
                    log_review_note(patient.patient_id);
                    setEncounterTime(patient.time_spent);
                    setTimeBasedCode(patient.time_based_code);
                    setICD_10_codes(patient.icd_10_codes);
                    setOrders(patient.orders);
                    setIsCopied(false);
                    setDiarizedTranscript(patient.diarized_transcript);
                    setDiarizedTranscriptCsvUrl(
                      patient.diarized_transcript_csv_url
                    );

                    // set isEditing back to false on close button
                    setIsEditing(false);
                  }}
                >
                  Done
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditPatient;

import React, { useState, useEffect } from "react";
import Fuse from "fuse.js";

export default function AddCodeModal({
  showAddCode,
  setShowAddCode,
  setCodesChange,
  selectedCodes,
  setSelectedCodes,
  setIsPrimaryCode,
  setIsSecondaryCode,
  isPrimaryCode,
  isSecondaryCode,
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState(""); // for debounced value
  const [filteredCodes, setFilteredCodes] = useState([]);
  const [selectedCode, setSelectedCode] = useState(null);
  const [fuseObject, setFuseObject] = useState(null);
  const [warningMessage, setWarningMessage] = useState("");

  // Load data once, build Fuse
  useEffect(() => {
    if (!showAddCode) return;
    async function loadData() {
      try {
        const response = await fetch("/json/ICD10Data.json");
        const data = await response.json();
        const fuse = new Fuse(data, {
          keys: ["code", "long"],
          threshold: 0.3,
        });
        setFuseObject(fuse);
        setFilteredCodes([]);
      } catch (error) {
        console.error("Failed to load ICD-10 data:", error);
      }
    }
    loadData();
  }, [showAddCode]);

  // Debounce the user’s raw searchQuery
  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      setDebouncedQuery(searchQuery.trim());
    }, 300); // wait 300ms

    // Cleanup the timeout if the user keeps typing
    return () => clearTimeout(delayDebounce);
  }, [searchQuery]);

  // Run the actual search on debouncedQuery instead of searchQuery
  useEffect(() => {
    if (!fuseObject) return;

    if (!debouncedQuery) {
      setFilteredCodes([]);
      setWarningMessage("");
      return;
    }

    // Search
    const results = fuseObject.search(debouncedQuery, { limit: 50 });
    let items = results.map((r) => r.item);

    // Filter out unspecified codes and handle warnings
    const queryUpper = debouncedQuery.toUpperCase();
    const blockedTerms = [
      "D509",
      "D649",
      "ANEMIA, UNSPECIFIED",
      "IRON DEFICIENCY ANEMIA, UNSPECIFIED",
    ];

    // Remove from search results
    items = items.filter(
      (icd) =>
        icd.code.toUpperCase() !== "D509" && icd.code.toUpperCase() !== "D649"
    );

    // If exact match is blocked
    if (blockedTerms.includes(queryUpper)) {
      setWarningMessage(
        "Not recommended to add unspecified codes, please search for a more specific code."
      );
    } else {
      setWarningMessage("");
    }

    setFilteredCodes(items);
  }, [debouncedQuery, fuseObject]);

  const handleClose = () => {
    setShowAddCode(false);
    setSearchQuery("");
    setDebouncedQuery("");
    setFilteredCodes([]);
    setSelectedCode(null);
    setWarningMessage("");
  };

  function insertPeriodAfterThirdChar(codeString) {
    if (codeString.length <= 3) {
      return codeString;
    }
    return codeString.slice(0, 3) + "." + codeString.slice(3);
  }

  const handleAdd = () => {
    if (!selectedCode) return;
    const formattedCode = insertPeriodAfterThirdChar(selectedCode.code);
    const codeType = isPrimaryCode ? "Primary" : "Secondary";

    const newCodeObject = {
      code: formattedCode,
      name: selectedCode.long || "",
      codeType: codeType,
    };

    const updatedCodesArray = [...selectedCodes.codes, newCodeObject];
    const updatedCodes = { ...selectedCodes, codes: updatedCodesArray };
    setSelectedCodes(updatedCodes);
    setCodesChange(true);
    setIsPrimaryCode(false);
    setIsSecondaryCode(false);
    handleClose();
  };

  return showAddCode ? (
    <div className="fixed bg-gray-800 bg-opacity-80 inset-0 flex items-center justify-center z-50">
      <div className="relative flex flex-col items-center bg-white p-8 rounded-lg shadow-lg w-[80vw] max-w-[600px]">
        <button
          onClick={handleClose}
          className="text-lg absolute top-3 right-5 font-extrabold text-accent1"
        >
          X
        </button>
        <h1 className="text-2xl text-accent1 font-bold mt-4 mb-2">
          Add ICD-10 Code
        </h1>
        <p className="text-accent1 mb-4">Search for a code below:</p>

        <div className="relative w-full mb-4">
          <input
            type="text"
            placeholder="Type search..."
            className="focus:placeholder-white border-0 text-light-grey
                       rounded-lg shadow-knowtex-shadow pl-10 pr-4 py-2 w-full"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setSelectedCode(null);
            }}
          />
          <div className="absolute inset-y-0 left-3 flex items-center">
            <svg
              className="w-5 h-5 text-light-grey"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth="2"
                d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
        </div>

        {warningMessage && (
          <div className="text-red-500 text-sm font-semibold mb-2">
            {warningMessage}
          </div>
        )}

        <div className="w-full max-h-60 overflow-auto border border-gray-300 rounded-md">
          {filteredCodes.length === 0 && debouncedQuery.length > 1 && (
            <div className="p-2 text-accent1">No results found.</div>
          )}
          {filteredCodes.map((icd, idx) => (
            <div
              key={icd.code + "-" + idx}
              className={`p-2 hover:bg-accent2 hover:text-purple-500 cursor-pointer
              ${
                selectedCode?.code === icd.code
                  ? "bg-accent2 text-white"
                  : "bg-white text-accent1"
              }`}
              onClick={() => setSelectedCode(icd)}
            >
              {icd.code}: {icd.long}
            </div>
          ))}
        </div>

        <button
          className="bg-accent2 hover:bg-purple2 text-white py-2 px-4 rounded mt-4"
          onClick={handleAdd}
          disabled={!selectedCode}
        >
          Add
        </button>
      </div>
    </div>
  ) : null;
}
